export const OSVersionsInstallers = {
	unknown: {
		name: 'Unknown',
		link: 'https://vk.com/desktop_app'
	},
	mac: {
		name: 'Mac',
		link: 'https://desktop.userapi.com/mac/master/vk.dmg'
	},
	win: {
		'win-64': {
			name: 'Windows 64-bit',
			link: 'https://desktop.userapi.com/win64/master/vk_setup.exe'
		},
		'win-32': {
			name: 'Windows 32-bit',
			link: 'https://desktop.userapi.com/win32/master/vk_setup.exe'
		},
	},
	lin: {
		'u-16-64': {
			name: 'Ubuntu 16.04 64-bit',
			link: 'https://desktop.userapi.com/get_last?platform=linux64&branch=master&packet=deb'
		},
		'u-16-32': {
			name: 'Ubuntu 16.04 32-bit',
			link: 'https://desktop.userapi.com/get_last?platform=linux32&branch=master&packet=deb'
		},
		'u-16-armhf': {
			name: 'Ubuntu 16.04 armhf',
			link: 'https://desktop.userapi.com/get_last?platform=linux-armv7l&branch=master&packet=deb'
		},
		'u-14-64': {
			name: 'Ubuntu 14.04 64-bit',
			link: 'https://desktop.userapi.com/get_last?platform=trusty64&branch=master&packet=deb'
		},
		'u-13-32': {
			name: 'Ubuntu 14.04 32-bit',
			link: 'https://desktop.userapi.com/get_last?platform=trusty32&branch=master&packet=deb'
		},
		'f-64': {
			name: 'Fedora 64-bit',
			link: 'https://desktop.userapi.com/get_last?platform=linux64&branch=master&packet=rpm'
		},
		'f-32': {
			name: 'Fedora 32-bit',
			link: 'https://desktop.userapi.com/get_last?platform=linux32&branch=master&packet=rpm'
		},
		'arch-l-64': {
			name: 'Arch Linux 64-bit',
			link: 'https://aur.archlinux.org/packages/vk-messenger'
		},
		'l-64': {
			name: 'Other Linux 64-bit',
			link: 'https://desktop.userapi.com/linux64/master/vk.zip'
		},
		'l-32': {
			name: 'Other Linux 32-bit',
			link: 'https://desktop.userapi.com/linux32/master/vk.zip'
		},
		'l-armv7l': {
			name: 'Other Linux armv7l',
			link: 'https://desktop.userapi.com/linux-armv7l/master/vk.zip'
		},
	}
};
