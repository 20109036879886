import React from 'react'

function parseLink(text,pref,cfg = {}) {
	// eslint-disable-next-line
	const regexp = /(\[[a-zA-Z\@\:\/\/\.0-9\-_]+\|.*?\]|<.*?>)/gmu
	let parts = text.split(regexp)

	if (parts.length === 1) {
		return parts[0]
	}
	let res = []
	parts.forEach( (t,i) => {
		if (t.indexOf('[') === 0 && !cfg['noLink']) {
			const linkPrefix = /(((https|http):\/\/)?(www\.)?)/
			let tag = t.split('|')
			let href = 'http://' + tag[0].replace('[', '').replace(linkPrefix, '')
			let text = tag[1].replace(']', '')
			res.push(<a href={href} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{text}</a>)
		} else if (t.indexOf('<link') === 0) {
			let tag = t.split('|')
			let href = tag[0].replace('<link', '')
			let text = tag[1].replace('link>', '')
			res.push(<a href={href} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{text}</a>)
		} else if (t.indexOf('<') === 0 && !cfg['noStrong']) {
			res.push(<strong key={pref + '_' + i}>{t.substr(1, t.length-2)}</strong>)
		} else {
			res.push(t)
		}
	} )
	return res
}

export function nToBr(string, cfg = {}) {
	string = string || ""
	if (!cfg['noTypography']) {
        string = string.replace(/&shy;/g, "\u00AD")
        string = string.replace(/&nbsp;/g, "\u00A0")
        string = string.replace(/&#8209;/g, "\u2011")
        string = string.replace(/&quot;/g, "\u0022")
        string = string.replace(/&#x2F;/g, "\u002F")
    }
    let stringArray = string.split('\n')
    let length = stringArray.length
    let result = []
    for (let i = 0; i < length; i++) {
		result.push(parseLink(stringArray[i], i, cfg))
        if (i !== length - 1) {
            result.push(<br key={i}/>)
        }
    }
    return result
}

export function getOS() {
	const {userAgent} = window.navigator,
		os = {"Windows": "win", "Mac": "mac", "Linux": "lin", "Unknown": "unknown"};
	let OSType="Unknown";

	if (~userAgent.indexOf("Win")) OSType="Windows";
	if (~userAgent.indexOf("Mac")) OSType="Mac";
	if (~userAgent.indexOf("X11") || ~userAgent.indexOf("Linux") || ~userAgent.indexOf("Ubuntu")) OSType="Linux";

	return {OSType, OSName: OSType, OSClass: os[OSType]}
}
