export async function setViewport(viewportContent) {
	if (window.landing !== undefined) {
		try {
			await window.landing.setViewport(viewportContent)
		} catch (e) {
			console.log(e)
		}
	}
}

export async function getViewportSize () {
	try {
		const sizes = await window.landing.getViewportSize();

		document.querySelector('#root').style.maxWidth = sizes.width + 'px';
		await window.landing.updateSize(['#root']);

		setTimeout(() => {window.landing.updateSize(['#root'])},1000);

		return {width: sizes.width, height: sizes.height}
	} catch (e) {
		console.log(e)
	}
}

export async function setFrameSize() {
	const {width, height} = await window.landing.getViewportSize();

	window.landing.setSize({width, height});
}
