export default function uk() {
	return {
		translation: {
			header_button_download: 'Завантажити для {{platform}}',
			header_button_download_short: 'Завантажити',
			header_note_mac: 'Потрібно macOS 10.9 або вище',
			header_note_win: 'Потрібно Windows 7 або вище',
			header_note_unknown: 'Доступно для macOS, Windows і&nbsp;Linux',
			header_version_other: 'Інші',

			info_title: 'Зручний додаток для&nbsp;обміну повідомленнями у&nbsp;VK',
			info_title_mob: 'Зручний додаток для&nbsp;обміну повідомленнями у&nbsp;VK',
			info_chats_title: 'Будьте на зв\'язку',
			info_chats_text: 'Ваші друзі з&nbsp;VK вже у&nbsp;додатку',
			info_calls_title: 'Зручні дзвінки',
			info_calls_text: 'Телефонуйте у&nbsp;VK прямо з&nbsp;комп\'ютера, а&nbsp;прийняти виклик співрозмовник зможе де&nbsp;завгодно',
			info_followers_title: 'Спілкуйтеся з підписниками',
			info_followers_text: 'Просте перемикання на&nbsp;повідомлення спільнот',
			info_button_public: 'Перейти до спільноти',

			footer_public_link: 'Спільнота VK',
			footer_terms_link: 'Умови використання',
			footer_lang_link: 'English',
			footer_lang_link_uk: 'English',

			lang_to_translate: 'en',
			lang_to_translate_uk: 'en'
		}
	}
}
