import React, { Component } from 'react';
import L from "../../lang/L"
import {nToBr} from "../../tools/helpers";
import './Info.css';

class Info extends Component {

	renderItem(item,index) {
		return (
			<div className={`Info__item Info__item_${item}`} key={index}>
				<div className="Info__item_title">{nToBr(L.t(`info_${item}_title`))}</div>
				<div className="Info__item_text">{nToBr(L.t(`info_${item}_text`))}</div>
			</div>
		)
	}

	render() {
		const {OSClass, lang} = this.props;
		const items = ['chats', 'calls', 'followers'];

		return (
			<div className="Info">
				<div className="DesktopMessenger__inner">
					<div className="Info__title">
						{nToBr(L.t(window.innerWidth > 959 ? 'info_title' : 'info_title_mob'))}
					</div>
					<div className="Info__list">
						{items.map((item,index) => this.renderItem(item,index))}
					</div>
					<div className={`Info__screen Info__screen_${OSClass} Info__screen_${lang}`}/>
					<a className="Info__button_public"
						 href="https://vk.com/desktop_app"
						 target="_blank"
						 rel="noreferrer noopener">{L.t('info_button_public')}</a>
					<div className="Info__list_mob">
						{items.map((item,index) => this.renderItem(item,index))}
					</div>
				</div>
			</div>
		);
	}
}

export default Info;
