import React, { Component } from 'react';
import L from "../../lang/L"
import './Footer.css';

class Footer extends Component {

	render() {
		const {changeLang, inIframe} = this.props;

		return (
			<div className="Footer">
				<div className="DesktopMessenger__inner">
					<div className="Footer__left">
						<a href="https://vk.com/desktop_app"
							 target="_blank"
							 rel="noreferrer noopener"
							 className="Footer__link Footer__link_public">
							{L.t('footer_public_link')}
						</a>
						<a href="https://vk.com/terms"
							 target="_blank"
							 rel="noreferrer noopener"
							 className="Footer__link Footer__link_terms">
							{L.t('footer_terms_link')}
						</a>
					</div>
					<div className="Footer__right">
						<div className="Footer__link Footer__link_lang"
								 onClick={() => changeLang( L.t(`lang_to_translate${inIframe ? '' : '_uk'}`) )}>
							{L.t(`footer_lang_link${inIframe ? '' : '_uk'}`)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer;
