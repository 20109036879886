export default function en() {
	return {
		translation: {
			header_button_download: 'Download for {{platform}}',
			header_button_download_short: 'Download',
			header_note_mac: 'MacOS 10.9 or later required',
			header_note_win: 'Windows 7 or later required',
			header_note_unknown: 'Available for macOS, Windows and&nbsp;Linux',
			header_version_other: 'Other',

			info_title: 'Simple and&nbsp;Convenient Messaging App for&nbsp;VK',
			info_title_mob: 'Simple and&nbsp;Convenient Messaging App for&nbsp;VK',
			info_chats_title: 'Stay in Touch',
			info_chats_text: 'Your VK&nbsp;friends are&nbsp;already using the&nbsp;app',
			info_calls_title: 'Convenient Сalling',
			info_calls_text: 'Make VK calls right from your computer and&nbsp;whoever you’re calling can answer on&nbsp;any device',
			info_followers_title: 'Interact with Followers',
			info_followers_text: 'Easily switch to&nbsp;community messages',
			info_button_public: 'Go to community',

			footer_public_link: 'VK Community',
			footer_terms_link: 'Terms and Conditions',
			footer_lang_link: 'Русский',
			footer_lang_link_uk: 'Українська',

			lang_to_translate: 'ru',
			lang_to_translate_uk: 'uk'
		}
	}
}
