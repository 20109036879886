export default function ru() {
	return {
		translation: {
			header_button_download: 'Загрузить для {{platform}}',
			header_button_download_short: 'Скачать',
			header_note_mac: 'Требуется macOS 10.9 или выше',
			header_note_win: 'Требуется Windows 7 или выше',
			header_note_unknown: 'Доступно для macOS, Windows и&nbsp;Linux',
			header_version_other: 'Другие',

			info_title: 'Удобное приложение для&nbsp;обмена&nbsp;сообщениями ВКонтакте',
			info_title_mob: 'Удобное приложение для&nbsp;обмена сообщениями ВКонтакте',
			info_chats_title: 'Будьте на связи',
			info_chats_text: 'Все диалоги и&nbsp;беседы из&nbsp;ВКонтакте уже там',
			info_calls_title: 'Удобные звонки',
			info_calls_text: 'Звоните с&nbsp;видео и&nbsp;без, собеседник сможет принять звонок где&nbsp;угодно',
			info_followers_title: 'Общайтесь с подписчиками',
			info_followers_text: 'Простое переключение на&nbsp;сообщения сообществ',
			info_button_public: 'Перейти в сообщество',

			footer_public_link: 'Сообщество ВКонтакте',
			footer_terms_link: 'Условия использования',
			footer_lang_link: 'English',

			lang_to_translate: 'en'
		}
	}
}
