import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import L from './lang/L'
import './style/index.css';
import DesktopMessenger from './components/DesktopMessenger/DesktopMessenger'

const startParams = queryString.parse(window.location.search);
const availableLangs = {0: 'ru', 3: 'en'};
// const inIframe = window.self !== window.top;
const inIframe = true;

const defaultLang =
	!inIframe ? 'uk' :
	availableLangs[startParams.language] ?
		availableLangs[startParams.language] :
		availableLangs[startParams['parent_language']] ?
			availableLangs[startParams['parent_language']] : 'ru';

L.init(defaultLang);

ReactDOM.render(<DesktopMessenger curLang={defaultLang} inIframe={inIframe} />, document.getElementById('root'));
