import React, { Component } from 'react';
import L from "../../lang/L"
import './Header.css';
import {nToBr} from "../../tools/helpers";
import {OSVersionsInstallers} from "../../tools/OSVersionsInstallers";

class Header extends Component {

	state = {
		headerScrolled: false
	};

	constructor(props) {
		super(props);

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.hideDropDownOnBody = this.hideDropDownOnBody.bind(this);
		this.changeWinVersion = this.changeWinVersion.bind(this);
		this.translateVersionName = this.translateVersionName.bind(this);
	}

	componentWillMount() {
		if (this.props.OSName === 'Windows') {
			this.setState({ OSVersionSelected: 'win-64', dropDownIsOpen: false })
		} else if (this.props.OSName === 'Linux') {
			this.setState({ OSVersionSelected: 'u-16-64', dropDownIsOpen: false })
		}
	}

	componentDidMount() {
		const nodeWrap = document.querySelector('.DesktopMessenger');

		nodeWrap.addEventListener('scroll', () => {
			if (window.innerWidth > 959) {
				const scrolled = nodeWrap.scrollTop;

				if (scrolled > 15 && !this.state.headerScrolled) this.setState({headerScrolled: true});
				if (scrolled <= 15 && this.state.headerScrolled) this.setState({headerScrolled: false})
			}
		});

		document.body.addEventListener('click', this.hideDropDownOnBody)
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.hideDropDownOnBody)
	}

	hideDropDownOnBody(event) {
		if (!~event.target.className.indexOf('Header__cta_download')) {
			this.setState({ dropDownIsOpen: false })
		}
	}

	toggleDropDown() {
		this.setState({ dropDownIsOpen: !this.state.dropDownIsOpen });

		if (!this.state.dropDownIsOpen) {
			const dropDown = document.querySelector('.Header__cta_download_dropdown');
			const top = dropDown.getBoundingClientRect().top;
			const height = document.querySelector('.Header__cta_download_dropdown_list').getBoundingClientRect().height;

			dropDown.style.maxHeight = `${height}px`;

			this.setState({ dropDownIsSmall: window.innerHeight - top < height });
		}
	}

	changeWinVersion(event) {
		this.setState({ OSVersionSelected: event.currentTarget.dataset.version });
		this.toggleDropDown()
	}

	translateVersionName(version, name) {
		return version.indexOf('l-') === 0 ? name.replace('Other', L.t('header_version_other')) : name;
	}

	renderSingleButton(OSName, OSClass) {
		return (
			<div className="Header__cta_download">
				<a href={OSVersionsInstallers[OSClass].link}
					 className={`Header__button Header__button_${OSClass}`}
					 target="_blank"
					 rel="noopener noreferrer">
					<div className="Header__button_inner">
						{OSClass === 'mac' ?
							L.t('header_button_download', {platform: OSName}) :
							L.t('info_button_public')
						}
					</div>
				</a>
			</div>
		)
	}

	renderDiffVersionsDownload(OSClass) {
		let {OSVersionSelected, dropDownIsOpen, dropDownIsSmall} = this.state,
			versions = OSVersionsInstallers[OSClass];

		OSVersionSelected = OSVersionSelected !== undefined ?
			OSVersionSelected :
			OSClass === 'lin' ? 'u-16-64' : 'win-64';

		return (
			<div className={`Header__cta_download Header__cta_download_${OSClass}`}>
				<div className={`Header__cta_download_select${dropDownIsOpen ? ' Header__cta_download_select_opened' : ''}`}>
					<div className="Header__cta_download_selected"
							 onClick={this.toggleDropDown}>
						{this.translateVersionName(OSVersionSelected, versions[OSVersionSelected].name)}
					</div>
					<div className={`Header__cta_download_dropdown${dropDownIsSmall ? ' Header__cta_download_dropdown_small' : ''}`}>
						<div className="Header__cta_download_dropdown_inner">
							<div className="Header__cta_download_dropdown_list">
								{Object.keys(versions).map((item,index) => this.renderOption(item, versions[item].name, index))}
							</div>
						</div>
					</div>
				</div>
				<a href={versions[OSVersionSelected].link}
					 className={`Header__button Header__button_${OSClass}`}
					 target="_blank"
					 rel="noopener noreferrer">
					<div className="Header__button_inner">
						{L.t('header_button_download_short')}
					</div>
				</a>
			</div>
		)
	}

	renderOption(version, name, index) {
		const {OSVersionSelected} = this.state;

		return (
			<div data-version={version}
					 key={index}
					 onClick={this.changeWinVersion}
					 className={`Header__cta_download_option ${version === OSVersionSelected ? ' Header__cta_download_option_selected' : ''}`}>
				{this.translateVersionName(version, name)}
			</div>
		)
	}

	render() {
		const {OSName, OSClass} = this.props;

		return (
			<div className={`Header${this.state.headerScrolled ? ' Header__scroll' : ''}`}>
				<div className="DesktopMessenger__inner">
					<div className="Header__logo"/>
					<div className="Header__cta">
						{OSName === 'Unknown' || OSName === 'Mac' ?
								this.renderSingleButton(OSName, OSClass) :
								this.renderDiffVersionsDownload(OSClass)
						}
						{(OSName !== 'Linux') &&
							<div className="Header__note">{nToBr(L.t(`header_note_${OSClass}`))}</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default Header;
