import React, { Component } from 'react';
import {getViewportSize, setFrameSize} from "../../tools/landingApiHelper";
import Header from "../Header/Header"
import Info from "../Info/Info"
import Footer from "../Footer/Footer"
import L from "../../lang/L"
import './DesktopMessenger.css';
import {getOS} from "../../tools/helpers";


class DesktopMessenger extends Component {

	state = {
		curLang: this.props.curLang,
		langWasChanged: false
	};

	constructor(props) {
		super(props);

		this.changeLang = this.changeLang.bind(this)
	}

	componentDidMount() {
		if (window.landing !== undefined) {
			window.addEventListener('message', (e) => {
				if (e.data.method && e.data.method.name === 'resize') {
					if (window.innerWidth > 959) setFrameSize();
					getViewportSize()
						.then((res) => {
							this.setState({lastHeight: res.height, lastWidth: res.width})
						})
				}
			});

			getViewportSize()
				.then((res) => {
					this.setState({lastHeight: res.height, lastWidth: res.width})
				})
		}
	}

	changeLang(lang) {
		L.init(lang);
		this.setState({
			curLang: lang,
			langWasChanged: true
		});
		setFrameSize();
	}

	render() {
		const {OSName, OSClass} = getOS();

		return (
			<div className={`DesktopMessenger DesktopMessenger_${OSClass}`}>
				<Header OSName={OSName} OSClass={OSClass} />
				<Info OSName={OSName} OSClass={OSClass} lang={this.state.curLang} />
				<Footer changeLang={this.changeLang} inIframe={this.props.inIframe} />
			</div>
		);
	}
}

export default DesktopMessenger;
